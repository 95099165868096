<template>
    <div>
        <ScrollPanel :style="edit? 'width: 100%; height: 600px': 'width: 100%; height: 600px'">
            <div class="p-grid">
                <DataTable :value="students" :filters="filters" scrollable :paginator="true" dataKey="id"
                           paginatorPosition="bottom" :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                           :loading="loading"  @page="onPage($event)" class="content">
                    <template #header v-if="enroll">
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left v-if="enroll.is_holder_student === 'y'">
                                <div class="p-col-12">
                                    <p><strong>Titular: </strong> {{ enroll?.contract?.holder?.name}} {{ enroll?.contract?.holder?.last_name}}</p>
                                    <p> <strong>Cédula: </strong>{{ enroll?.contract?.holder?.dni }}</p>
                                    <p> <strong>Email: </strong>{{ enroll?.contract?.holder?.email }}</p>
                                </div>


                            </template>
                            <template #right>
                                <Button label="Nuevo registro" icon="pi pi-plus" class="p-button-success p-mr-2"
                                        @click="newStudent()" v-if="edit" />
                            </template>
                        </Toolbar>
                    </template>

                    <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class"></Column>
                    <Column header="Opciones"  headerStyle="width: 200px">
                        <template #body="slotProps">
                            <Button v-tooltip="'Editar'" icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2" @click="editItem(slotProps.data)"/>
                            <Button  v-tooltip="'Eliminar'" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteStudent($event, slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </ScrollPanel>
        <Dialog v-model:visible="editDialog" :style="{width: '900px'}" header="Datos de estudiante" :modal="true"
                class="p-fluid">
            <student ref="studentForm" :enroll="enroll" :current="currentItem" :edit="true" v-if="editDialog" :block="false"></student>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false;getData()"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveStudent"/>
            </template>
        </Dialog>
    </div>

</template>

<script>
    import service from '../../service/student.service'
    import {defineAsyncComponent} from "vue";
    import {mapState} from "vuex";

    export default {
        name: "StudentList",
        components: {
            student: defineAsyncComponent(() => import('./Student')),
        },
        props: {
            enroll: Object,
            edit: Boolean
        },
        data() {
            return {
                columns: [
                    {field: 'dni', header: 'DNI'},
                    {field: 'name', header: 'Nombre'},
                    {field: 'last_name', header: 'Apellido'},
                    {field: 'email', header: 'Correo', class:'content'},
                    {field: 'phone', header: 'Teléfono'},
                ],
                page: {
                    page: 1,
                    per_page: 5,
                    total: 0,
                    order: "desc"
                },
                students: [],
                data: {},
                filters: {},
                submitted: false,
                loading: false,
                editDialog: false,
                verifyDataDialog: false
            }
        },
        methods: {
            deleteStudent(event, data) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Desea eliminar el registro?',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.loading = true;
                        service.delete(data.id).then(() => {
                            this.getData();
                        }).catch(err => {
                            const message = err.response.data;
                            let error = this.$utils.formatError(message);
                            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                        }).finally(() => this.loading = false);
                    },
                    reject: () => {
                    }
                });
            },
            editItem(val) {
                this.currentItem = val;
                this.$nextTick(() => {
                    this.editDialog = true;
                })

            },
            onPage(event) {
                this.page.page = event.page + 1;
                this.getData()
            },
            newStudent() {
                this.currentItem = null;
                this.editDialog = true;
            },
            getData() {
                this.loading = true;
                if(this.enroll) {
                    service.getByEnrollment(this.enroll.id, this.page).then(x => {
                        this.students = x.data;
                        this.page.total = this.students.length;
                    }).finally(() =>  this.loading = false)
                } else {
                    service.get(null, this.page).then(x => {
                        this.students = x.data.data;
                        this.page.total = x.data.total;

                    }).finally(() =>  this.loading = false)
                }

            },
            saveStudent() {
              (this.$refs.studentForm.$refs.studentForm.save()).then(() => {
                        this.getData();
                        this.editDialog = false;
                    }).catch(err => {
                        const message = err?.response?.data;
                        let error = this.$utils.formatError(message);
                        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                    })
            },
        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">

    .p-fieldset {
        margin-top: 15px;
    }
    .content {
        overflow-wrap: break-word;
    }

</style>
